<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.dataColors')"
      :has-action="false"
    />

    <v-card
      class="py-6 px-6 mt-3 text-left"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row v-if="colors.chartColors.length > 0">
        <v-col
          cols="12"
          class="pb-0"
        >
          <p class="text-h6 mb-0">
            {{ $t('dataColors.primaryChartColors') }}
          </p>
        </v-col>

        <v-col cols="6">
          <ColorItem
            :value="colors.chartColors[0].value"
            :default-color="defaultChartColors[0].value"
            class="ml-5"
            @input="changeColor('chart', 0, $event)"
          />
        </v-col>

        <v-col cols="6">
          <ColorItem
            :value="colors.chartColors[1].value"
            :default-color="defaultChartColors[1].value"
            class="ml-5"
            @input="changeColor('chart', 1, $event)"
          />
        </v-col>
      </v-row>

      <p class="text-h6 mb-0 mt-8">
        {{ $t('dataColors.statusColors') }}
      </p>

      <ColorsTable
        :headers="headers"
        :items="colors.statusColors"
        :default-items="defaultStatusColors"
        item-key="id"
        class="mt-3"
        type="status"
        @change-color="changeColor"
      />

      <p class="text-h6 mb-0 mt-8">
        {{ $t('dataColors.priorityColors') }}
      </p>

      <ColorsTable
        :headers="headers"
        :items="colors.priorityColors"
        :default-items="defaultPriorityColors"
        item-key="id"
        class="mt-3"
        type="priority"
        @change-color="changeColor"
      />

      <p class="text-h6 mb-0 mt-8">
        {{ $t('dataColors.typeColors') }}
      </p>

      <ColorsTable
        :headers="headers"
        :items="colors.typeColors"
        :default-items="defaultTypeColors"
        item-key="id"
        class="mt-3"
        type="type"
        @change-color="changeColor"
      />

      <div class="mt-4 d-flex justify-center">
        <v-btn
          color="blue"
          width="300px"
          elevation="0"
          class="white--text text-capitalize"
          @click="onUpdateColors()"
        >
          {{ $t('dataColors.updateColors') }}
        </v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as _ from 'lodash'
import makeUserService from '@/services/api/user';

import SectionHeader from '@/components/Form/SectionHeader.vue';
import ColorItem from '@/components/Settings/DataColors/ColorItem.vue';
import ColorsTable from '@/components/Settings/DataColors/ColorsTable.vue';

import { showSuccessToast, showErrorToast } from '@/utils/toast';

import {
  chartColors as defaultChartColors,
  statusColors as defaultStatusColors,
  priorityColors as defaultPriorityColors,
  typeColors as defaultTypeColors,
} from '@/constants/colors.js';

export default {
  name: 'DataColors',

  components: {
    SectionHeader,
    ColorItem,
    ColorsTable,
  },

  data () {
    return {
      headers: [
        {
          text: this.$t('dataColors.color'),
          sortable: false,
          value: 'id',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('testCases'),
          sortable: false,
          value: 'testCase',
          width: 300,
        },
        {
          text: this.$t('testRuns'),
          value: 'testRun',
          sortable: false,
          width: 300,
        },
        {
          text: this.$t('defects'),
          value: 'defect',
          sortable: false,
          width: 300,
        },
      ],
      colors: {
        chartColors: [],
        statusColors: [],
        priorityColors: [],
        typeColors: [],
      },
      defaultChartColors: defaultChartColors,
      defaultStatusColors: defaultStatusColors,
      defaultPriorityColors: defaultPriorityColors,
      defaultTypeColors: defaultTypeColors,
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'user/user' }),
  },

  mounted() {
    this.colors = {
      chartColors: _.cloneDeep(this.currentUser.preferences?.dataColors?.chartColors || defaultChartColors),
      statusColors: _.cloneDeep(this.currentUser.preferences?.dataColors?.statusColors || defaultStatusColors),
      priorityColors: _.cloneDeep(this.currentUser.preferences?.dataColors?.priorityColors || defaultPriorityColors),
      typeColors: _.cloneDeep(this.currentUser.preferences?.dataColors?.typeColors || defaultTypeColors),
    }
  },

  methods: {
    ...mapActions({
      setUser: 'user/setUser',
      setLoading: 'setLoading',
    }),

    changeColor(type, index, color) {
      this.colors[`${type}Colors`][index].value = color
    },

    async onUpdateColors() {
      this.setLoading({
        loading: true,
        loadingText: this.$t('dataColors.updatingDataColors')
      })

      const userService = makeUserService(this.$api);

      try {
        const response = await userService.updatePreferences({
          preferences: {
            ...this.currentUser.preferences,
            dataColors: {
              chartColors: this.colors.chartColors,
              statusColors: this.colors.statusColors,
              priorityColors: this.colors.priorityColors,
              typeColors: this.colors.typeColors,
            },
          }
        })

        this.setUser({
          ...this.currentUser,
          preferences: response.data.preferences,
        })

        showSuccessToast(this.$swal, this.$t('dataColors.colorsUpdated'))
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error')
      } finally {
        this.setLoading({
          loading: false,
        })
      }
    }
  }
}
</script>